import $ from 'jquery';
import app from './app';
// ---------------------------------------------------------
// Custom Functions for Front-End
// ---------------------------------------------------------
$(document).ready(function() {
	var body = $("body");
	var userAgent = window.navigator.userAgent;
	var appVersion = window.navigator.appVersion;
	if (document.documentMode || /Edge/.test(navigator.userAgent)) {
		body.addClass('ieMode');
	}

	let winw = $(window).width();
	let menuTrigger = $('.menu a');
	let blockNav = $('.hNavi');
	let naviClose = $('#naviClose');
	let headerHeight = $('#header').innerHeight();

	$(window).on('load resize', function() {
		winw = $(window).width();
		headerHeight = $('#header').innerHeight();
		if (winw > 768) {
			blockNav.css({
				'display': 'block'
			});
		} else {
			blockNav.css({
				'display': 'none'
			});
		}
	});

	menuTrigger.on('click', function() {
		$(this).stop().toggleClass('active');
		blockNav.stop().fadeToggle(300, 'linear');
	});

	naviClose.on('click', function() {
		menuTrigger.click();
	});

	$('.navi a, .fNavi a').on('click', function() {
		let ele = $(this);
		let hrefElement = ele.attr('href');
		$('.navi a').removeClass('active');
		if(hrefElement.startsWith('/#')){
			$(".navi a[href='" + hrefElement + "']").addClass('active');
		}
		if (winw <= 768) {
			menuTrigger.click();
		}
	});

	// Anchor link
	$(window).on('load', function() {
			if(location.hash){
					let idElement = location.hash.slice(1);
					let offTop = $('#' + idElement).offset().top - headerHeight;
					setTimeout(function(){
						$('body, html').stop().animate({
								scrollTop: offTop
						}, 500);
					}, 300);
			}
	});

	$('.anchor').on('click', function(e) {
			let hrefElement = $(this).attr('href');
			if(hrefElement.startsWith('#')){
					let anchorLink = hrefElement.slice(1);
					let offTop = $('#' + anchorLink).offset().top - headerHeight;
					$('body, html').stop().animate({
							scrollTop: offTop
					}, 500);
			}
			if(hrefElement.startsWith('/#')){
					let anchorLink = hrefElement.slice(2);
					let offTop = $('#' + anchorLink).offset().top - headerHeight;
					$('body, html').stop().animate({
							scrollTop: offTop
					}, 500);
			}
	});
});
